#gridContainer {
  max-height: 800px;
}

.options {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: relative;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option-container {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.option {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.option-caption {
  white-space: nowrap;
  margin: 0 8px;
}
