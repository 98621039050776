.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .well {
    background: #eeefff;
    padding: 15px;
  }
  
  .tab {
    /* background: transparent; */
    /* padding: 45px 15px; */
    width: 150px;
    /* color: #1122ff; */
    /* border: none; */
    /* cursor: pointer; */
  }
  
  .tab-content {
    padding: 15px 5px;
  }
  
  .tab.active {
    /* background: #1122ff; */
    /* color: #000; */
    /* border: 1px solid #ddd; */
  }
  
  .nav-tabs {
    display: flex;
    width: 300px;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #ddd;
  }
  
  .add-tab-button {
    background: none;
    border: none;
  }
  
  .edit-mode-button {
    background: #1122ff;
    color: #fff;
  }
  
  .save-button {
    background: green;
    color: #fff;
  }
  
  .edit-tab-name {
    background: #1122ff;
    color: #fff;
  }
  