@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    font-weight: 900;
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Black';
    font-weight: 900;
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Bold';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Black';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ubuntu-Bold';
    font-weight: 900;
    src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Ubuntu-Medium';
    font-weight: 900;
    src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    font-weight: 900;
    src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Black';
    font-weight: 900;
    src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
